import { apiGetpoint, apiGetclassify, apiSearchGre, apiGreStore } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            addExaminationDialog: false,//弹框显示隐藏
            editDialog: false,//是否为编辑弹框
            formInline: {
                title: '',//标题
                editTitle: '',//编辑的标题
                poid: null,//ID
                editPoid: null,//编辑ID
                test_time: '',//时间
                clid: null,//分类
                contents: '',//内容
            },
            regionOptions: [],//考点信息
            classifyOptions: [],//分类信息
            alikeGre: [], //相似的机经
            alinkNo: false,

            btnLoading: false
        }
    },
    mounted() {
        this.getGetpoint()
        this.getGetclassify()
    },
    methods: {
        // 获取考点信息
        getGetpoint() {
            apiGetpoint().then((res) => {
                this.regionOptions = res.data
            })
        },
        // 获取分类信息
        getGetclassify() {
            apiGetclassify().then((res) => {
                this.classifyOptions = res.data
                this.formatClassify(this.classifyOptions)

            })
        },
        // 格式化分类信息
        formatClassify(arr) {
            arr.forEach(item => {
                item.label = item.name;
                item.value = item.id;
                item.children = item.child;
                if (item.child != undefined) {
                    this.formatClassify(item.child)
                }
            });
        },
        onSubmit() {
            // console.log('submit!');
        },
        // inputBlur(flag, refName) {
        //     // 隐藏下拉时失去焦点（针对ie）
        //     if (!flag) {
        //         this.$refs[refName].$children[0].blur();
        //     }
        // }
        // 搜索相似机经
        searchGre() {
            if (this.formInline.title == '' || this.formInline.clid == '') {
                return
            }
            let paramsBase = {
                gre_title: this.formInline.title,
                clid: this.formInline.clid[this.formInline.clid.length - 1],
                page: 1
            }
            apiSearchGre(paramsBase).then(res => {
                this.alikeGre = res.data;
                if (res.data.length == 0) {
                    this.alinkNo = true
                }
            })
        },
        // 发布机经
        storeGre() {
            if (this.formInline.contents.length < 5) {
                this.$layer.msg('回忆要点请大于5个字')
            }
            this.btnLoading = true
            let paramsBase = {
                poid: parseInt(this.formInline.poid),
                clid: parseInt(this.formInline.clid),
                contents: this.formInline.contents,
                test_time: this.formInline.test_time,
                title: this.formInline.title,
            }
            if (this.editDialog) {
                paramsBase.poid = this.formInline.editPoid
            }
            apiGreStore(paramsBase).then(res => {
                if (res.code != 200) {
                    this.btnLoading = false
                } else {
                    this.addExaminationDialog = false;
                    this.btnLoading = false
                    this.$layer.msg(res.msg)
                    this.formInline.title = ''
                    this.formInline.poid = null
                    this.formInline.test_time = ''
                    this.formInline.clid = null
                    this.formInline.contents = ''
                }
            })
        }
    },
}